import React from 'react'
import { withStyles } from '@material-ui/core';
import "./styles.css"
import { styles } from "./styles"
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {FaStar, FaUniversity} from 'react-icons/fa'
import {GoBriefcase} from 'react-icons/go'


const WorkExperience = props => {

    return (
<VerticalTimeline>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    date="2019 - present"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={<GoBriefcase />}
  >
    <h3 className="vertical-timeline-element-title">Infinity Fragment</h3>
    <h4 className="vertical-timeline-element-subtitle">Data Scientist</h4>
    <p>
      Image detection and classification model selection and training. Training automation with AWS Sagemaker. Creation of serveless architecture with the use of AWS Lambda and ONNX.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="2018 - 2019"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={<GoBriefcase />}
  >
    <h3 className="vertical-timeline-element-title">Instasense</h3>
    <h4 className="vertical-timeline-element-subtitle">Full-Stack Developer</h4>
    <p>
      Use of Kotlin, Ionic, Firebase, SQLite, Azure to create various apps such as "VOX IoT", "Santova Mobile", "MathU". Python was used for multiple automation tasks.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="2018 - 2020"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={<FaUniversity />}
  >
    <h3 className="vertical-timeline-element-title">Honours in Computer Engineering</h3>
    <h4 className="vertical-timeline-element-subtitle">University of Pretoria</h4>
    <p>
      Computer Vision, SLAM, Photogrammetry and Machine Learning
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="2014 - 2017"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={<FaUniversity />}
  >
    <h3 className="vertical-timeline-element-title">Bachelor in Computer Engineering</h3>
    <h4 className="vertical-timeline-element-subtitle">University of Pretoria</h4>
    <p>
      Electronics, Embedded Systems, Basic Artificial Intelligence, General Programing and Algorithms, Physics and Math. 
    </p>
    <h5>Achieved: </h5>
    <ul>
      <li>Graduated with distinction and bronze merit award (third best in my year).</li>
      <li>Silver merit award (second best in my year) for second and third year of studies</li>
      <li>Golden key (Member ID: 13075012)</li>
    </ul>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
    icon={<FaStar />}
  />
</VerticalTimeline>

    )
}

WorkExperience.propTypes = {
};

export default withStyles(styles)(WorkExperience);

