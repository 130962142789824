import React from 'react'
import "./AboutMe.css"
import { Button } from '@material-ui/core';

const cdDownloadURl = "https://firebasestorage.googleapis.com/v0/b/sjnarmstrong-com.appspot.com/o/Downloadables%2FCV.pdf?alt=media"

const AboutHeader = props => {

    return (
        <div className="MainAboutMeDiv">
            <h3>About Me</h3>
            <div className="AboutMeTextDiv">I am a Software Engineer based in Pretoria, South Africa. I strive to contribute to the development of innovative products and further my skill set in various fields especially machine learning and photogrammetry.</div>
            <Button variant="outlined" size="large" color="primary" href={cdDownloadURl}>
                Download CV
            </Button>
        </div>
    )
}


export default AboutHeader;

