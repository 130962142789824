import React from 'react'
import { AboutMe, Skills } from "..";

const AboutPage = props => {

    return (
        <React.Fragment>
            <AboutMe/>
            <Skills/>
        </React.Fragment>
    )
}


export default AboutPage;

