import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { styles } from "./Styles"
import LinearProgress from '@material-ui/core/LinearProgress';

import VizSensor from 'react-visibility-sensor';


class SkillIndicator extends React.Component {

  state = {
    showSkill: false
  }

  theme = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiLinearProgress: {
        // Name of the rule
        barColorPrimary: {
          // Some CSS
          backgroundColor: this.props.barColor || "red",
        },
      },
    },
  });

  updateShowSkill = (isVisible) => {
    if (isVisible && !this.state.showSkill){
        setTimeout(()=>this.setState({showSkill: true}), 500)
    }
  }

  render() {
    const { altText, classes, icon, skillPercentage } = this.props;
    const { showSkill } = this.state;

    return (
        
          <div className={classes.skillDiv}>
            <img className={classes.skillIcon} alt={altText} src={icon}/>

            <div className={classes.progressBarDiv}>
              <div className={classes.skillText}>{altText}</div>
              <VizSensor onChange={this.updateShowSkill}>

                <MuiThemeProvider theme={this.theme}>
                  <LinearProgress className={classes.skillBar} variant="determinate" value={showSkill? skillPercentage: 0} classes={{
                    determinate: classes.pbDet
                  }}/>
                </MuiThemeProvider>
                  
              </VizSensor>
            </div>
          </div>

        
    );
  }
}

SkillIndicator.propTypes = {
    altText: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    icon: PropTypes.string.isRequired,
    barColor: PropTypes.string,
    skillPercentage: PropTypes.number.isRequired
};

export default withStyles(styles)(SkillIndicator);

          /* 
            // <Grid item xs={6} sm={4} md={3} lg={2} xl={1} className={classes.skillDiv}>
            <div className={classes.skillDiv}>
            <img className={classes.skillIcon} alt={altText} src={icon}/>
            <div className={classes.progressBarDiv}>
              <div className={classes.skillText}>{altText}</div>
              <VizSensor onChange={this.updateShowSkill}>

                <MuiThemeProvider theme={this.theme}>
                  <LinearProgress className={classes.skillBar} variant="determinate" value={showSkill? skillPercentage: 0} classes={{
                    determinate: classes.pbDet
                  }}/>
                </MuiThemeProvider>
                  
              </VizSensor>
            </div>
          </div> */
          // </Grid>
        