import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core';
import { SkillIndicator, SkillContainer } from '..';
import { python, html, css, cpp, typescript, pytorch, docker, gitlab, react, angular, firebase, aws } from "../../Assets"
import { styles } from "./Styles"
// import SkillBar from 'react-skillbars';
// import {SKILLS} from './skillslist'

const Skills = props => {
    const { classes } = props;
    return (
        <React.Fragment>

        <SkillContainer ContainerName="Skills">
            <SkillIndicator barColor="#FDD835" altText="Python" icon={python} skillPercentage={90}/>
            <SkillIndicator barColor="#FFC107" altText="PyTorch" icon={pytorch} skillPercentage={90}/>
            <SkillIndicator barColor="#FF7043" altText="AWS" icon={aws} skillPercentage={70}/>
            <SkillIndicator barColor="#F44336" altText="Docker" icon={docker} skillPercentage={70}/>
            <SkillIndicator barColor="#F06292" altText="Gitlab" icon={gitlab} skillPercentage={70}/>
            <SkillIndicator barColor="#BA68C8" altText="React" icon={react} skillPercentage={65}/>
            <SkillIndicator barColor="#9575CD" altText="Angular" icon={angular} skillPercentage={65}/>
            <SkillIndicator barColor="#5C6BC0" altText="Firebase" icon={firebase} skillPercentage={65}/>
            <SkillIndicator barColor="#42A5F5" altText="Typescript" icon={typescript} skillPercentage={65}/>
            <SkillIndicator barColor="#03A9F4" altText="HTML" icon={html} skillPercentage={60}/>
            <SkillIndicator barColor="#26C6DA" altText="CSS" icon={css} skillPercentage={60}/>
            <SkillIndicator barColor="#252229" altText="C++" icon={cpp} skillPercentage={60}/>
        </SkillContainer>
        <br/>
        <br/>

        <SkillContainer ContainerName="Pluralsight">  
            <div><img className={classes.pImg} alt="Python Results" src={require('../../Assets/pluralsight/python.png')}></img></div>
            <div><img className={classes.pImg} alt="PyTorch Results" src={require('../../Assets/pluralsight/pytorch.png')}></img></div>
            <div><img className={classes.pImg} alt="Git Results" src={require('../../Assets/pluralsight/git.png')}></img></div>
            <div><img className={classes.pImg} alt="Docker Results" src={require('../../Assets/pluralsight/docker.png')}></img></div>
        </SkillContainer>

            
        </React.Fragment>
    )
}

Skills.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Skills);

