const styles = {

    skillDiv: {
        display: 'block',
        maxWidth: 230,
        width: "45%",
        boxSizing: 'border-box',
        padding: 15,
    },
    skillIcon: {
        width: '75%', 
        height: '75%',
        padding: "8px",
    },
    skillText:{
        margin: "5px 0px 20px 0px",
        fontSize: "1.2em"
    },
    skillBar: {
        marginLeft: 10,
        marginRight: 10,
    },
    pbDet: {
        transition: "transform 3s ease-out"
    },
}

// const styles_old = {
//     skillDiv: {
//         display: 'block',
//         maxWidth: 200,
//         width: "80%",
//         height: '100%',
//         boxSizing: 'border-box',
//         margin: '40px 40px 0px 40px',
//     },
//     InnerSkillDiv: {
//         boxSizing: 'border-box',
//         display: 'flex',
//         alignItems: 'center',
//         alignContent: 'center',
//         flexDirection: 'row',
//     },
//     skillIcon: {
//         width: '80%', 
//         height: '80%',
//         padding: "8px",
//     },
//     progressBarDiv: {
//         position: 'relative',
//         bottom: 0,
//     },

//     pbColPri: {
//         backgroundColor: "#F00",

//     },

//     skillGridItem: {
//         maxWidth: 200,
//         width: "80%",
//         height: '100%'
//     }
// }

export {styles}