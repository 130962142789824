const styles = theme =>({
    ///mainDiv:{
    //    position: "sticky",
    //    top: 0,
    //    zIndex: 1000
    //},
    seperator:{
        backgroundColor: "#252229",
        height: "2px",
        width: "100%"
    },
    skillCategory: {
        backgroundColor: "#252229",
        color: "#DDD",
        height: "40px",
        fontSize: "1.2em",
        verticalAlign: "center",
        textAlign: "center",
        width: "500px",
        [theme.breakpoints.down('sm')]:{
            width: "100%",
        }
    },
    verticalFlex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignSelf: "center",
        [theme.breakpoints.down('sm')]:{
            width: "100%",
        }
    },
    horisontalFlex:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignSelf: "center",
    },
    triangleTopLeft: {
        width: 0,
        height: 0,
        borderTop: "40px solid #252229",
        borderRight: "40px solid transparent",
        [theme.breakpoints.down('sm')]:{
            display: 'none',
        }
    },
    triangleTopRight: {
        width: 0,
        height: 0,
        borderTop: "40px solid #252229",
        borderLeft: "40px solid transparent",
        [theme.breakpoints.down('sm')]:{
            display: 'none',
        }
    },
    skillContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    }
});

export {styles}