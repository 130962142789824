import React, { Component } from 'react';
import './App.css';
import {AboutPage, AboutHeader} from "../../Components"
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import {FaLinkedin, FaGit, FaStackOverflow} from 'react-icons/fa'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#FFF',
      main: '#DDD',
      contrastText: '#252229',
    },
    secondary: {
      main: '#252229',
      contrastText: '#DDD',
    },
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPageId: 0,
      pageToLoad: AboutPage
    };
  }

  async setCorrectPage(id){
    const { default: pageToDisplay } = id === 2? await import("../../Components/Projects/Projects"):
                          id === 1? await import("../../Components/WorkExperience/WorkExperience"):
                          await import("../../Components/AboutPage/AboutPage");
    if (this.state.selectedPageId === id){
      this.setState({pageToLoad: pageToDisplay})
    }
  }

  routeToPage = (id)=>{
    console.log(id)
    this.setCorrectPage(id)
    this.setState({selectedPageId: id}) 
  }

  getLabelId = (id) => {
    return this.state.selectedPageId === id ? "selected" : ""
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
      <div className="App">
      
      <AboutHeader>
        <label htmlFor="homeButton" id={this.getLabelId(0)} className="navLabel">About            </label><button className="hiddenButton"  id="homeButton"  onClick={_=>this.routeToPage(0)}/>
        <label htmlFor="expButton"  id={this.getLabelId(1)} className="navLabel">Work Experience  </label><button className="hiddenButton"  id="expButton"   onClick={_=>this.routeToPage(1)}/>
        <label htmlFor="projButton" id={this.getLabelId(2)} className="navLabel">Projects         </label><button className="hiddenButton"  id="projButton"  onClick={_=>this.routeToPage(2)}/>
      </AboutHeader>
      <this.state.pageToLoad/>
      <div className="footer"> 
       <p className="footer-text">&copy; 2020 Sholto Armstrong</p> 
       <p class="footer-icon-containers">
          <a className="hidden-anchor" href="https://www.linkedin.com/in/sholto-armstrong-48421918a/">
            <FaLinkedin className="footer-icon"></FaLinkedin>
          </a>
          <a className="hidden-anchor" href="https://github.com/sjnarmstrong">
          <FaGit className="footer-icon"></FaGit>
          </a>
          <a className="hidden-anchor" href="https://stackoverflow.com/users/12564547/sholto-armstrong">
          <FaStackOverflow className="footer-icon"></FaStackOverflow>
          </a>

        </p>
       </div>
      </div>
      </MuiThemeProvider>
    );
  }
}

export default App;
