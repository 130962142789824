import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core';
import { styles } from "./Styles"

const SkillContainer = props => {

    const { classes, children, ContainerName } = props;
    return (
        <React.Fragment>
        
        <div className={classes.mainDiv}>
            <div className={classes.seperator}/>
            <div className={classes.horisontalFlex}>
                <div className={classes.verticalFlex}>
                    <div className={classes.triangleTopRight}/>
                    <div className={classes.skillCategory}>
                        {ContainerName}
                    </div>
                    <div className={classes.triangleTopLeft}/>
                </div>
            </div>
        </div>
        <div className={classes.skillContainer}>
            {children}
        </div>
        </React.Fragment>
    )
}

SkillContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    ContainerName: PropTypes.string.isRequired
};

export default withStyles(styles)(SkillContainer);

