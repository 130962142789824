import React from 'react'
import PropTypes from 'prop-types'
import {  withStyles } from '@material-ui/core';
import './styles.css'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {FaStar, FaUniversity} from 'react-icons/fa'
import {GoBriefcase} from 'react-icons/go'

const styles = {
    mainGrid: {
        fontSize: "2em"
    }
}
const Projects = props => {

    return (
        <VerticalTimeline>

<VerticalTimelineElement
className="vertical-timeline-element--uni"
date="2019 - present"
iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
icon={<FaUniversity />}
>
<h3 className="vertical-timeline-element-title">3D Scene Understanding</h3>
<h4 className="vertical-timeline-element-subtitle">University of Pretoria</h4>
<p>
    A platform to semantically segment and reconstruct a 3D scene from RGB-D images. This platform must make use of hierarchical information.
</p>
</VerticalTimelineElement>


        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2019 - present"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<GoBriefcase />}
        >
          <h3 className="vertical-timeline-element-title">Cognizance</h3>
          <h4 className="vertical-timeline-element-subtitle">Infinity Fragment</h4>
          <p>
            Planogram compliance for retailers with the use of computer vision.
            <br/><a href="https://www.futurefragment.com/">link</a>
          </p>
        </VerticalTimelineElement>

    
        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="2019"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<GoBriefcase />}
        >
        <h3 className="vertical-timeline-element-title">Avox</h3>
        <h4 className="vertical-timeline-element-subtitle">Infinity Fragment</h4>
        <p>
            Voice sample collection platform.
            <br/><a href="https://www.avox.futurefragment.com/#/">link</a>
        </p>
        </VerticalTimelineElement>


<VerticalTimelineElement
className="vertical-timeline-element--work"
date="2018"
iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
icon={<GoBriefcase />}
>
<h3 className="vertical-timeline-element-title">Santova Mobile</h3>
<h4 className="vertical-timeline-element-subtitle">Instasense</h4>
<p>
    An app to allow tracking of parcels.
    <br/><a href="https://play.google.com/store/apps/details?id=mobile.santova.com&hl=en">link</a>
</p>
</VerticalTimelineElement>


<VerticalTimelineElement
className="vertical-timeline-element--work"
date="2018"
iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
icon={<GoBriefcase />}
>
<h3 className="vertical-timeline-element-title">MathU</h3>
<h4 className="vertical-timeline-element-subtitle">Instasense</h4>
<p>
    A solution to help tutor mathematics and science.
    <br/><a href="http://www.mathu.io">link</a>
</p>
</VerticalTimelineElement>


<VerticalTimelineElement
className="vertical-timeline-element--work"
date="2018"
iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
icon={<GoBriefcase />}
>
<h3 className="vertical-timeline-element-title">Vox IoT</h3>
<h4 className="vertical-timeline-element-subtitle">Infinity Fragment</h4>
<p>
    An app that makes use of the sigfox network to track various things such as live-stock. It is possible to set up geofences with the app to get notifications as soon as an asset leaves a specified location.
    <br/>
    <a href="https://play.google.com/store/apps/details?id=za.co.voxtelecom.iot&hl=en_ZA">link</a>
</p>
</VerticalTimelineElement>


<VerticalTimelineElement
className="vertical-timeline-element--uni"
date="2017"
iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
icon={<FaUniversity />}
>
<h3 className="vertical-timeline-element-title">Semi-direct Visual Odometry</h3>
<h4 className="vertical-timeline-element-subtitle">University of Pretoria</h4>
<p>
    An implementation of SVO from first principals to run on a Raspberry Pi Zero.
</p>
</VerticalTimelineElement>


        <VerticalTimelineElement
          iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
          icon={<FaStar />}
        />
      </VerticalTimeline>
    )
}

Projects.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Projects);

