import React from 'react'
import PropTypes from 'prop-types'
import { Parallax } from 'react-parallax';
import { Avatar, Grid, withStyles } from '@material-ui/core';
import GithubCorner from 'react-github-corner';

const styles = {
    bigAvatar: {
        margin: 20,
        width: 250,
        height: 250,
        marginLeft: "100%",
        marginRight: "100%"
    },
    mainGrid: {
        minHeight: 400,
    },
    NameDiv: {
        color: 'white',
        padding: '10px 50px 0px 50px',
        fontSize: 50,
    },
    textDiv: {
        color: 'white',
        padding: '0px 50px 50px 50px',
        fontSize: 20,
    },
}
const AboutHeader = props => {

    const { classes, children } = props;
    return (
        <Parallax
            blur={4}
            bgImage={require('../../Assets/karthik-swarnkar-542019-unsplash.jpg')}
            bgImageAlt="coding"
            strength={500}>
            <Grid container 
                justify="center" 
                alignItems="center" 
                className={classes.mainGrid}>
                <Avatar 
                    alt="Sholto Armstrong" 
                    src={require('../../Assets/Face.jpg')}
                    className={classes.bigAvatar} /> 
                <Grid item xs={12} lg={9} >
                    <div className={classes.NameDiv}>
                        Sholto Armstrong
                    </div>
                </Grid>
                <Grid item xs={12} >
                    <div className={classes.textDiv}>
                        Data Scientist
                    </div>
                </Grid>
                    {children}
            </Grid>
            <GithubCorner 
                href="https://github.com/sjnarmstrong" 
                size={100}
                direction="left" />
        </Parallax>
    )
}

AboutHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AboutHeader);

