export const styles = {
    
    // outerSkillDiv: {
    //     width: "80%",
    //     paddingTop: 30
    // }
    pImg: {
        padding: 20,
        maxWidth: 500,
        width: "80%",
    }

}
